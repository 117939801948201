<template>
  <v-dialog
    v-if="!loading"
    v-model="dialogPostCurtida"
    @click:outside="$emit('update:dialogPostCurtida', false)"
    @keydown.esc="$emit('update:dialogPostCurtida', false)"
    max-width="850px"
  >
    <div class="accent d-flex px-6 py-4">
      <DialogPostImage
        v-if="dialogPostImage"
        :dialogPostImage.sync="dialogPostImage"
        :image="image"
      />

      <span class="text-h5 ">{{ editar ? "Editar Post" : "Post" }} </span>
      <v-spacer></v-spacer>
      <v-btn
        v-if="post[0].status === 0 ? true : false"
        text
        fab
        small
        @click="editar = !editar"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn text fab small @click="$emit('update:dialogPostCurtida', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-card v-if="!editar" tile class="pa-6">
      <v-row>
        <v-col
          @click="verImage"
          style="cursor: pointer"
          v-if="post[0].thumbnail"
          cols="12"
          :md="post[0].thumbnail ? '4' : '0'"
        >
          <v-img max-width="150px" :src="post[0].thumbnail"></v-img>
        </v-col>
        <v-col cols="12" :md="post[0].thumbnail ? '8' : '12'">
          <div class="d-flex align-center justify-center">
            <span class="text-body-2 font-weight-bold">
              {{ post[0].nome }}
            </span>
            <v-spacer></v-spacer>
            <span class="mr-2"
              >{{ post[0].qtde_curtidas }}
              <v-icon>mdi-cards-heart</v-icon></span
            >
            <span class="text-body-2 font-weight-bold">
              {{ formatDate(post[0].updated_at) }}
            </span>
          </div>
          <div class="mt-2">
            <span>
              {{ post[0].conteudo }}
            </span>
          </div>
          <div></div>
        </v-col>
      </v-row>
    </v-card>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card tile class="pa-6" v-if="editar">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="thumbnail">
                  <v-img :src="thumbnail" contain>
                    <div class="d-flex justify-end">
                      <v-btn
                        class="save white--text mr-1 mt-1"
                        fab
                        depressed
                        x-small
                        @click="onButtonClick"
                      >
                        <input
                          accept="image/*"
                          type="file"
                          class="d-none"
                          ref="uploader"
                          @change="onFileChanged"
                        /><v-icon>mdi-camera</v-icon></v-btn
                      >
                    </div>
                  </v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%;">
                  <vue-dropzone
                    ref="myVueDropzone"
                    :id="postInvalido ? 'dropzone-error' : 'dropzone'"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info">
                        mdi-image-plus
                      </v-icon>
                      <h3 class="text-subtitle-1 ">
                        Arrastar e soltar
                      </h3>
                      <div class="text-caption">
                        Clique para selecionar
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="9">
              <v-row dense class="px-4">
                <!-- Destaque -->
                <v-col cols="12">
                  <v-text-field dense readonly value="Destaque:">
                    <template v-slot:append>
                      <v-switch
                        v-model="post[0].destaque"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>
                <!-- Conteudo -->
                <v-col cols="12">
                  <v-textarea
                    v-model="post[0].conteudo"
                    label="Mensagem"
                    :class="postInvalido ? 'error-input' : ''"
                    dense
                  ></v-textarea>
                </v-col>
                <!-- Notificar -->
                <v-col v-if="post[0].destaque === true" cols="12">
                  <v-text-field dense readonly value="Notificar:">
                    <template v-slot:append>
                      <v-switch
                        v-model="post[0].notificar"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>
                <!-- Notificar Texto -->
                <v-col v-if="post[0].destaque === true" cols="12">
                  <v-text-field
                    v-model="post[0].notificar_texto"
                    label="Mensagem Notificaçao"
                    required
                    :rules="post[0].notificar === true ? formRules : [true]"
                    :disabled="!post[0].notificar ? true : false"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-btn text @click="editar = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="updatePost"
            :disabled="!validForm"
            color="save"
            class="white--text"
          >
            <v-icon left> mdi-content-save</v-icon>
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { one as getPost, putPost } from "@/api/admin/posts.js";
import dateLocalePortugues from "date-fns/locale/pt-BR";
import { parseISO, formatDistanceToNow } from "date-fns";
import { inputRequired } from "@/plugins/utils.js";
import { mapState } from "vuex";

export default {
  name: "dialogPostCurtida",

  props: {
    dialogPostCurtida: {
      type: Boolean,
      default: false,
    },
    post_id: {
      type: Number,
    },
  },

  data() {
    return {
      post: {},
      loading: true,
      dialogPost: false,
      editar: false,
      hoverFile: false,
      thumbnail: null,
      thumbnailUpload: null,
      validForm: true,
      post_original: {},
      formRules: [inputRequired],
      dialogPostImage: false,
      image: null,
      postInvalido: false,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
    DialogPostImage: () => import("./DialogPostImage.vue"),
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      this.thumbnailUpload = file;
      this.post[0].thumbnail = !this.post[0].thumbnail ? "" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.thumbnail = event.target.result;
      };
    },
    verImage() {
      this.dialogPostImage = true;
      this.image = this.post[0].thumbnail;
    },
    fileAdded(file) {
      this.thumbnailUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.thumbnail = event.target.result;
      };
    },
    fetchPost() {
      this.loading = true;
      getPost(this.post_id)
        .then((response) => {
          this.post = response;
          this.post_original = this.post[0];
          this.thumbnail = this.post[0].thumbnail;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    fetchPostOriginal() {
      this.loading = true;
      getPost(this.post_id)
        .then((response) => {
          this.post_original = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    formatDate(date) {
      let options = {
        includeSeconds: true,
        locale: dateLocalePortugues,
        addSuffix: true,
      };
      return formatDistanceToNow(parseISO(date), options);
    },

    updatePost() {
      if (this.post[0].conteudo || this.thumbnail) {
        if (JSON.stringify(this.post) === JSON.stringify(this.post_original)) {
          this.$toast.error("Por favor, faça uma mudança antes de salvar!");
          return;
        }

        if (this.$refs.form.validate()) {
          this.loading = true;
          const post = new FormData();
          if (this.thumbnailUpload) {
            post.append(
              "thumbnail",
              this.thumbnailUpload,
              this.thumbnailUpload.name
            );
          }
          let post_2 = {};

          post_2.conteudo = this.post[0].conteudo;
          post_2.destaque = this.post[0].destaque;
          post_2.notificar = this.post[0].notificar;
          if (this.post[0].notificar === true) {
            post_2.notificar_texto = this.post[0].notificar_texto;
          }
          for (let key in post_2) {
            if (
              post_2[key] !== null &&
              post_2[key] !== undefined &&
              post_2[key] !== ""
            ) {
              post.append(key, post_2[key]);
            }
          }
          putPost(this.post_id, post)
            .then((response) => {
              if (response.status === 200) {
                this.$toast.success("Atualizado com sucesso");
                this.loading = false;
                this.$emit("update:dialogPostCurtida", false);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } else {
        this.$toast.error(
          "Favor inserir uma mensagem ou uma imagem antes de postar"
        );
        this.postInvalido = true;
      }
    },
  },

  mounted() {
    this.fetchPost();
    this.fetchPostOriginal();
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
#dropzone-error {
  border: 2px dashed red;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
.error-input {
  ::v-deep .theme--light.v-label {
    color: red;
  }
  ::v-deep .v-input__slot:before {
    border-color: rgb(255 0 0) !important;
  }
}
</style>
